// Chat page (/chat), also M8

// React, Redux, DOM essentials
import React, { useState, useEffect, useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI components
import { 
  List,
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  Typography, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  IconButton, 
  Button,
  Tabs,
  Tab 
} from '@mui/material';

// MUI theme config
import { ThemeProvider, createTheme } from '@mui/material/styles';

// MUI icons
import AddIcon from '@mui/icons-material/Add';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

// Components
import CategoryList from 'components/chats/categoryList/CategoryList';
import ChatList from 'components/chats/chatList/ChatList';
import ChatListView from 'components/chats/ChatListView';
import ChatWindow from 'components/chats/chatWindow/ChatWindow';
import MemberProfile from 'components/userProfile/MemberProfile';
import ChatProfile from 'components/chats/chatProfile/ChatProfile';
import ChatProfileDrawer from 'components/chats/mobileProfileView/ChatProfileDrawer';

// Tutorial components
import PopupSlideshow from 'components/popup/Slideshow/PopupSlideshow';
import M8sPageTutorial from 'components/tutorial/M8s/M8sPageTutorial';
import HelpButton from 'components/tutorial/HelpButton';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


export default function ChatPage({ chats }) {
  const mobileLayout = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  
  // Profile view visibility control states (mobile only)
  const profileDrawerVisible = useSelector(state => state.chats.profileDrawerVisible);

  // Chatting user (non current login user) ID and info states 
  const [chatUserInfo, setChatUserInfo] = useState({});

  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
      <PopupSlideshow 
        content={<M8sPageTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible} />} 
        visible={tutorialVisible} 
        setVisible={setTutorialVisible}
        backdrop={true}
        association={"first-time-m8"}
      />
  );
  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-m8", true);
    setTutorialVisible(true);
  }
  useEffect(() => {
    localStorage.getItem("first-time-m8") && setTutorialVisible(true);
  },[])


  return (
    <ThemeProvider theme={lightTheme}>

      {/* First time user tutorial */}
      {/* {tutorialPopup} commented out because it wasn't working*/}
      
      {/* Main container */}
      <Box className="chat-page-container" sx={{display: 'flex', justifyContent: 'center', width: '100%', gap: 2}}>

        {/* Category/Chat list */}
        <ChatListView group={true} />

        {/* Main chat window */}
        <ChatWindow /> 

        {/* Profile window */}
        <ChatProfileDrawer />

        {/* Help button */}
        {/*mobileLayout && (
          chatId === null && <HelpButton onClick={handleHelpButtonClick}/>
        )*/}
        {/*!mobileLayout && <HelpButton onClick={handleHelpButtonClick}/>*/}

      </Box>
    </ThemeProvider>
  );
}
